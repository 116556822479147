import React, { useState, useEffect } from 'react';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import AccountManagement from './AccountManagement';
import { savePreferences as savePreferencesToFirestore, getPreferences } from './PreferencesService';

const Preferences = () => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [preferences, setPreferences] = useState({
    idealPrice: '',
    bedrooms: [],
    bathrooms: [],
    propertyType: [],
    squareFootage: '',
  });
  const [isAnyPrice, setIsAnyPrice] = useState(false);

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
      if (currentUser) {
        fetchUserPreferences(currentUser.uid);
      } else {
        setLoading(false);
      }
    });

    return () => unsubscribe();
  }, []);

  const fetchUserPreferences = async (userId) => {
    try {
      const savedPreferences = await getPreferences(userId);
      if (savedPreferences) {
        setPreferences(savedPreferences);
        setIsAnyPrice(savedPreferences.idealPrice === 'Any');
      }
    } catch (error) {
      console.error('Error fetching preferences:', error);
    } finally {
      setLoading(false);
    }
  };

  const handlePriceChange = (e) => {
    const value = e.target.value.replace(/\D/g, '');
    setPreferences(prev => ({ ...prev, idealPrice: value ? parseInt(value) : '' }));
    setIsAnyPrice(false);
  };

  const handleAnyPrice = () => {
    setPreferences(prev => ({ ...prev, idealPrice: '' }));
    setIsAnyPrice(!isAnyPrice);
  };

  const handleButtonToggle = (category, value) => {
    setPreferences(prev => ({
      ...prev,
      [category]: prev[category].includes(value)
        ? prev[category].filter(item => item !== value)
        : [...prev[category], value]
    }));
  };

  const handleSquareFootageSelect = (e) => {
    setPreferences(prev => ({ ...prev, squareFootage: e.target.value }));
  };

  const savePreferences = async (e) => {
    e.preventDefault();
    if (!isAnyPrice && !preferences.idealPrice) {
      alert('Please enter an Ideal Price or select Any');
      return;
    }
    if (preferences.bedrooms.length === 0) {
      alert('Please select at least one Bedroom option');
      return;
    }
    if (preferences.bathrooms.length === 0) {
      alert('Please select at least one Bathroom option');
      return;
    }
    if (preferences.propertyType.length === 0) {
      alert('Please select at least one Property Type');
      return;
    }
    if (!preferences.squareFootage) {
      alert('Please select a Square Footage option');
      return;
    }
    try {
      const preferencesToSave = isAnyPrice ? { ...preferences, idealPrice: 'Any' } : preferences;
      await savePreferencesToFirestore(preferencesToSave);
      alert('Preferences saved successfully!');
    } catch (error) {
      console.error('Error saving preferences:', error);
      alert('Failed to save preferences. Please try again.');
    }
  };

  if (loading) {
    return <div className="max-w-6xl mx-auto py-8 transition-opacity duration-300 opacity-0">Loading...</div>;
  }

  return (
    <div className={`max-w-6xl mx-auto py-8 transition-opacity duration-300 `}>
      <AccountManagement user={user} />
      <h2 className="text-2xl font-bold mb-4 pt-8">Buying Preferences</h2>
      <form className="space-y-6" onSubmit={savePreferences}>
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">Ideal Price</label>
          <div className="flex items-center space-x-4">
            <div className="relative rounded-md shadow-sm flex-grow">
              <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                <span className="text-gray-500 sm:text-sm">$</span>
              </div>
              <input
                type="text"
                value={isAnyPrice ? '' : preferences.idealPrice}
                onChange={handlePriceChange}
                className="block w-full rounded-md border-gray-300 pl-7 pr-12 focus:border-emerald-500 focus:ring-emerald-500 sm:text-sm outline outline-1 outline-gray-300 focus:outline-emerald-500"
                placeholder="0"
                disabled={isAnyPrice}
              />
              {isAnyPrice && (
                <div className="absolute inset-y-0 right-0 flex items-center pr-3">
                  <span className="text-gray-500 sm:text-sm">Any</span>
                </div>
              )}
            </div>
            <button
              type="button"
              onClick={handleAnyPrice}
              className={`px-3 py-2 text-sm font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500 outline outline-1 ${
                isAnyPrice
                  ? 'bg-emerald-600 text-white hover:bg-emerald-700 outline-emerald-600'
                  : 'bg-white text-gray-700 hover:bg-gray-50 outline-gray-300'
              }`}
            >
              Any
            </button>
          </div>
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">Bedrooms</label>
          <div className="grid grid-cols-5 gap-2">
            {['1', '2', '3', '4', '5+'].map((value) => (
              <button
                key={value}
                type="button"
                onClick={() => handleButtonToggle('bedrooms', value)}
                className={`px-4 py-2 text-sm font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500 outline outline-1 ${
                  preferences.bedrooms.includes(value)
                    ? 'bg-emerald-600 text-white outline-emerald-600'
                    : 'bg-white text-gray-700 hover:bg-gray-50 outline-gray-300'
                }`}
              >
                {value}
              </button>
            ))}
          </div>
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">Bathrooms</label>
          <div className="grid grid-cols-5 gap-2">
            {['1', '1.5', '2', '3', '4+'].map((value) => (
              <button
                key={value}
                type="button"
                onClick={() => handleButtonToggle('bathrooms', value)}
                className={`px-4 py-2 text-sm font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500 outline outline-1 ${
                  preferences.bathrooms.includes(value)
                    ? 'bg-emerald-600 text-white outline-emerald-600'
                    : 'bg-white text-gray-700 hover:bg-gray-50 outline-gray-300'
                }`}
              >
                {value}
              </button>
            ))}
          </div>
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">Property Type</label>
          <div className="grid grid-cols-5 gap-2">
            {['Single Family', 'Condo', 'Townhouse', 'Multi Family', 'Other'].map((value) => (
              <button
                key={value}
                type="button"
                onClick={() => handleButtonToggle('propertyType', value)}
                className={`px-4 py-2 text-sm xs:text-xs font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500 outline outline-1 ${
                  preferences.propertyType.includes(value)
                    ? 'bg-emerald-600 text-white outline-emerald-600'
                    : 'bg-white text-gray-700 hover:bg-gray-50 outline-gray-300'
                }`}
              >
                {value === 'Townhouse' ? (
                  <>
                    <span className="sm:hidden">Town-<br />house</span>
                    <span className="hidden sm:inline">Townhouse</span>
                  </>
                ) : (
                  value
                )}
              </button>
            ))}
          </div>
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">Square Footage</label>
          <select
            value={preferences.squareFootage}
            onChange={handleSquareFootageSelect}
            className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-emerald-500 focus:border-emerald-500 sm:text-sm rounded-md outline outline-1 outline-gray-300 focus:outline-emerald-500"
            required
          >
            <option value="">Select square footage</option>
            <option value="0 to 1000">0 to 1000</option>
            <option value="1000 to 1500">1000 to 1500</option>
            <option value="1500 to 2000">1500 to 2000</option>
            <option value="2000 to 2500">2000 to 2500</option>
            <option value="2500 to 3000">2500 to 3000</option>
            <option value="3000 to 4000">3000 to 4000</option>
            <option value="4000 to 5000">4000 to 5000</option>
            <option value="5000+">5000+</option>
          </select>
        </div>

        <button
          type="submit"
          className="w-full px-4 py-2 text-sm font-medium text-white bg-emerald-600 rounded-md hover:bg-emerald-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500 outline outline-1 outline-emerald-600"
        >
          Save Preferences
        </button>
      </form>
    </div>
  );
};

export default Preferences;
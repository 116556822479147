export const filterComparableHomes = (similarHomes, originalProperty) => {
  const { price, bedrooms, bathrooms } = originalProperty;
  const priceMin = price * 0.8;
  const priceMax = price * 1.2;
  const bedroomsMin = Math.floor(bedrooms - 0.5);
  const bedroomsMax = Math.ceil(bedrooms + 0.5);
  const bathroomsMin = bathrooms - 1;
  const bathroomsMax = bathrooms + 1;
  return similarHomes.filter(home => {
    const meetsPrice = home.price >= priceMin && home.price <= priceMax;
    const meetsBedrooms = home.bedrooms >= bedroomsMin && home.bedrooms <= bedroomsMax;
    const meetsBathrooms = home.bathrooms >= bathroomsMin && home.bathrooms <= bathroomsMax;
    return meetsPrice && meetsBedrooms && meetsBathrooms;
  }).slice(0, 10); // Limit to 10 homes
};
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth, GoogleAuthProvider} from 'firebase/auth';

const firebaseConfig = {
  apiKey: "AIzaSyACPGcIsCF2voOiGQCUtQqRftUYvuZ4L7s",
  authDomain: "unrepped-inc.firebaseapp.com",
  projectId: "unrepped-inc",
  storageBucket: "unrepped-inc.appspot.com",
  messagingSenderId: "305946835902",
  appId: "1:305946835902:web:dec40fd529394b8d6897ef",
  measurementId: "G-LC8W1W6H0R"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);
const provider = new GoogleAuthProvider();

export { auth, db, provider };
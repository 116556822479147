import React from 'react';

const Title = () => (
  <div className="text-center mb-8 mt-16">
    <div className="-m-1.5 p-1.5 flex justify-center items-center">
      <span className="sr-only">Unrepped</span>
      <img
        alt="Unrepped logo"
        src="/unrepped-logotype.png"
        className="h-20 w-auto"
      />
    </div>
    <h2 className="text-xl font-semibold text-gray-600">Be Your Own Real Estate Agent</h2>
  </div>
);

export default Title;
import React, { useState, useEffect } from "react";
import { formatNumber } from "../../utils/CustomComponents";
import { calculateMortgage } from "./MortgageCalculatorService";
import { fetchInterestRates } from "../../utils/MortgageApi";

function MortgageCalculatorView({ lastAnalyzedProperty }) {
  const [price, setPrice] = useState(lastAnalyzedProperty?.price || "");
  const [downPayment, setDownPayment] = useState(20);
  const [interestRate, setInterestRate] = useState("");
  const [loanTerm, setLoanTerm] = useState(30);
  const [lastUpdated, setLastUpdated] = useState(null);
  const [rate15Year, setRate15Year] = useState(null);
  const [rate30Year, setRate30Year] = useState(null);
  const [selectedRateType, setSelectedRateType] = useState("30");

  const handleInterestRateChange = (e) => {
    const value = e.target.value;
    if (value === '' || /^\d*\.?\d*$/.test(value)) {
      setInterestRate(value);
      setSelectedRateType(null);
    }
  };

  const handleInterestRateStep = (step) => {
    const currentRate = parseFloat(interestRate) || 0;
    const decimalPlaces = (interestRate.toString().split('.')[1] || '').length;
    const newRate = (currentRate + step).toFixed(decimalPlaces);
    setInterestRate(newRate);
    setSelectedRateType(null); // Unselect the rate type buttons
  };

  useEffect(() => {
    if (lastAnalyzedProperty && lastAnalyzedProperty.price) {
      setPrice(lastAnalyzedProperty.price);
    }

    const fetchRates = async () => {
      const rates = await fetchInterestRates();
      setRate15Year(rates.rate15Year);
      setRate30Year(rates.rate30Year);
      setInterestRate(rates.rate30Year);
      setLastUpdated(rates.lastUpdated);
    };

    fetchRates();
  }, [lastAnalyzedProperty]);

  const handleRateTypeChange = (type) => {
    setSelectedRateType(type);
    setInterestRate(type === "15" ? rate15Year : rate30Year);
    setLoanTerm(type === "15" ? 15 : 30);
  };

  return (
    <div className="max-w-4xl mx-auto">
      <h3 className="text-2xl font-bold mb-4">Mortgage Calculator</h3>
      <form className="space-y-4">
        <div>
          <label htmlFor="price" className="block text-sm font-medium text-gray-700">Home Price ($)</label>
          <input type="number" id="price" value={price} className="w-full p-2 border border-gray-300 rounded"
            onChange={(e) => setPrice(e.target.value)} 
          />
        </div>
        <div>
          <label htmlFor="downPayment" className="block text-sm font-medium text-gray-700">Down Payment (%)</label>
          <input type="number" id="downPayment" value={downPayment} className="w-full p-2 border border-gray-300 rounded"
            onChange={(e) => setDownPayment(e.target.value)}
          />
        </div>
        <div>
          <label htmlFor="interestRate" className="block text-sm font-medium text-gray-700">Interest Rate (%)</label>
          <div className="flex items-center">
            <input
              type="number"
              id="interestRate"
              value={interestRate}
              onChange={handleInterestRateChange}
              onKeyDown={(e) => {
                if (e.key === 'ArrowUp') {
                  e.preventDefault();
                  handleInterestRateStep(0.01);
                } else if (e.key === 'ArrowDown') {
                  e.preventDefault();
                  handleInterestRateStep(-0.01);
                }
              }}
              min="0"
              step="any"
              className="w-full p-2 border border-gray-300 rounded"
            />
            <div className="ml-2 flex space-x-2">
              <button
                type="button"
                onClick={() => handleRateTypeChange("15")}
                className={`px-4 py-2 text-sm font-medium rounded whitespace-nowrap ${
                  selectedRateType === "15"
                    ? "bg-emerald-600 text-white"
                    : "bg-gray-200 text-gray-700 hover:bg-gray-300"
                }`}
              >
                15-year
              </button>
              <button
                type="button"
                onClick={() => handleRateTypeChange("30")}
                className={`px-4 py-2 text-sm font-medium rounded whitespace-nowrap ${
                  selectedRateType === "30"
                    ? "bg-emerald-600 text-white"
                    : "bg-gray-200 text-gray-700 hover:bg-gray-300"
                }`}
              >
                30-year
              </button>
            </div>
          </div>
          {lastUpdated && (
            <p className="mt-0 text-sm text-gray-500">
              (from St. Louis Fed, updated {lastUpdated})
            </p>
          )}
        </div>
        <div>
          <label htmlFor="loanTerm" className="block text-sm font-medium text-gray-700">Loan Term (years)</label>
          <input type="number" id="loanTerm" value={loanTerm} className="w-full p-2 border border-gray-300 rounded"
            onChange={(e) => setLoanTerm(parseInt(e.target.value))}
          />
        </div>
      </form>
      <div className="mt-6 p-4 bg-emerald-100 rounded-lg shadow-inner">
        <h4 className="text-lg font-semibold text-emerald-600">Estimated Monthly Payment</h4>
        <p className="text-3xl font-bold text-emerald-700">
          ${formatNumber(calculateMortgage(price, downPayment, interestRate, loanTerm))}
        </p>
      </div>
    </div>
  );
}

export default MortgageCalculatorView;
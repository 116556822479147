import React from "react";
import { formatNumber, formatHomeStatus } from "../../utils/CustomComponents";
import { filterComparableHomes } from "./CMAService";

function CMAView({ recentAnalyses, comparableHomes }) {
  const originalProperty = recentAnalyses.length > 0 ? recentAnalyses[0] : null;

  if (!originalProperty) {
    return (
      <div className="text-center py-4">
        No property selected for analysis.
      </div>
    );
  }

  // Filter the comparable homes
  const filteredHomes = filterComparableHomes(comparableHomes, originalProperty);

  return (
    <div className="max-w-4xl mx-auto">
      <h3 className="text-2xl font-bold mb-4">Comparable Market Analysis</h3>
      <p className="text-sm italic text-gray-600 mb-4">
        Analyze similar properties in the area to understand the local real estate market and how your property of interest compares.
      </p>
      <p className="mb-4">
        <strong>Property Address:</strong>{" "}
        {originalProperty.zillowUrl ? (
          <a
            href={originalProperty.zillowUrl}
            target="_blank"
            rel="noopener noreferrer"
            className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600"
          >
            {originalProperty.address}
          </a>
        ) : (
          originalProperty.address
        )}
      </p>
      {filteredHomes.length === 0 ? (
        <div className="text-center py-4">No comparable properties available.</div>
      ) : (
        <div className="overflow-x-auto">
          <table className="w-full">
            <thead>
              <tr className="border-b">
                <th className="py-2 text-left">Address</th>
                <th className="py-2 text-left">Price</th>
                <th className="py-2 text-left">Square Footage</th>
                <th className="py-2 text-left">Bedrooms</th>
                <th className="py-2 text-left">Bathrooms</th>
                <th className="py-2 text-left">Price/sqft</th>
                <th className="py-2 text-left">Status</th>
                <th className="py-2 text-left">Zillow Link</th>
              </tr>
            </thead>
            <tbody>
              {filteredHomes.map((home, index) => (
                <tr key={home.zpid || index} className="border-b">
                  <td className="py-2">{home.address}</td>
                  <td className="py-2">${formatNumber(home.price)}</td>
                  <td className="py-2">{formatNumber(home.livingArea)}</td>
                  <td className="py-2">{home.bedrooms}</td>
                  <td className="py-2">{home.bathrooms}</td>
                  <td className="py-2">
                    ${home.livingArea ? (home.price / home.livingArea).toFixed(2) : "N/A"}
                  </td>
                  <td className="py-2">{formatHomeStatus(home.homeStatus)}</td>
                  <td className="py-2">
                    {home.zillowUrl ? (
                      <a
                        href={"https://zillow.com" + home.zillowUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600"
                      >
                        Link
                      </a>
                    ) : (
                      "N/A"
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
}

export default CMAView;
import axios from "axios";

export const fetchInterestRates = async () => {
  const API_KEY = "ec0ff29875826d53f212f62bd418fa5e";
  const BASE_URL = "https://api.stlouisfed.org/fred/series/observations";

  const createUrl = (seriesId) => {
    const params = new URLSearchParams({
      series_id: seriesId,
      api_key: API_KEY,
      file_type: "json",
      sort_order: "desc",
      limit: "1",
    });
    return `https://corsproxy.io/?${encodeURIComponent(`${BASE_URL}?${params.toString()}`)}`;
  };

  try {
    const [response15Year, response30Year] = await Promise.all([
      axios.get(createUrl("MORTGAGE15US")),
      axios.get(createUrl("MORTGAGE30US")),
    ]);
    const rate15 = parseFloat(response15Year.data.observations[0].value);
    const rate30 = parseFloat(response30Year.data.observations[0].value);
    return {
      rate15Year: rate15,
      rate30Year: rate30,
      lastUpdated: new Date(response30Year.data.observations[0].date).toLocaleDateString(),
    };
  } catch (error) {
    console.error("Error fetching interest rates:", error);
    return {
      rate15Year: 6,
      rate30Year: 7,
      lastUpdated: "N/A",
    };
  }
};

import React, { useState, useEffect } from 'react';

function GreenFlagsPopup({ onClose, onSave, greenFlags, setGreenFlags }) {
  const defaultFlags = ['Big yard', 'New kitchen', 'Good location'];
  const [selectedFlags, setSelectedFlags] = useState([]);
  const [customFlags, setCustomFlags] = useState('');

  useEffect(() => {
    // Initialize selected flags and custom flags based on existing green flags
    const existingFlags = greenFlags.split(',').map(flag => flag.trim());
    setSelectedFlags(existingFlags.filter(flag => defaultFlags.includes(flag)));
    setCustomFlags(existingFlags.filter(flag => !defaultFlags.includes(flag)).join(', '));
  }, [greenFlags]);

  const handleFlagToggle = (flag) => {
    setSelectedFlags(prev => 
      prev.includes(flag) ? prev.filter(f => f !== flag) : [...prev, flag]
    );
  };

  const handleSave = () => {
    const customFlagsArray = customFlags.split(',').map(flag => flag.trim()).filter(Boolean);
    const allFlags = [...new Set([...selectedFlags, ...customFlagsArray])];
    setGreenFlags(allFlags.join(', '));
    onSave(allFlags);
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="bg-white rounded-lg shadow-lg w-11/12 max-w-md p-6">
        <h2 className="text-xl text-center font-semibold mb-4">Green Flags</h2>
        <p className="text-gray-600 mb-2">
          Add or modify things you like about the home, separated by commas.
        </p>
        
        {/* Default Flags Buttons */}
        <div className="flex flex-wrap xs:flex-nowrap gap-2 justify-center">
          {defaultFlags.map((flag) => (
            <button
              key={flag}
              onClick={() => handleFlagToggle(flag)}
              className={`px-3 py-2 font-medium rounded-full text-sm xs:text-xs transition-colors
                ${
                  selectedFlags.includes(flag)
                    ? 'bg-green-500 text-white hover:bg-green-600'
                    : 'bg-gray-200 text-gray-700 hover:bg-gray-300 hover:text-gray-800'
                }`}
              > 
              {flag}
            </button>
          ))}
        </div>
        
        {/* Custom Flags Input */}
        <input
          type="text"
          value={customFlags}
          onChange={(e) => setCustomFlags(e.target.value)}
          placeholder="e.g., Large windows, Quiet neighborhood"
          className="w-full px-3 py-2 border border-gray-300 rounded mb-4 focus:outline-none focus:ring-2 focus:ring-green-500"
        />
        <div className="flex justify-center">
          <button onClick={onClose} className="bg-gray-300 text-gray-700 px-4 py-2 rounded mr-2 hover:bg-gray-400 transition-colors">
            Cancel
          </button>
          <button onClick={handleSave} className="bg-green-600 text-white px-4 py-2 rounded hover:bg-green-500 transition-colors">
            Add Green Flags!
          </button>
        </div>
      </div>
    </div>
  );
}

export default GreenFlagsPopup;
import React, { useState } from "react";
import { renderChecklistItem } from '../utils/CustomComponents';

function InspectionChecklist() {
  const [checklist, setChecklist] = useState({
    exterior: {
      roof: false,
      siding: false,
      foundation: false,
      landscaping: false,
    },
    interior: {
      walls: false,
      floors: false,
      ceilings: false,
      windows: false,
      doors: false,
    },
    systems: {
      electrical: false,
      plumbing: false,
      hvac: false,
      waterHeater: false,
    },
    other: {
      insulation: false,
      pests: false,
      mold: false,
      radon: false,
    },
  });

  const handleCheckboxChange = (category, item) => {
    setChecklist((prevState) => ({
      ...prevState,
      [category]: {
        ...prevState[category],
        [item]: !prevState[category][item],
      },
    }));
  };

  // Function to render a section of the checklist
  const renderChecklistSection = (title, items) => (
    <div className="mb-6">
      <h4 className="text-lg font-semibold mb-2">{title}</h4>
      <div className="space-y-2">
        {Object.entries(items).map(([key, value]) => 
          renderChecklistItem(
            `${title}-${key}`,
            value,
            () => handleCheckboxChange(title.toLowerCase(), key),
            key.charAt(0).toUpperCase() + key.slice(1)
          )
        )}
      </div>
    </div>
  );

  // Render the complete checklist
  return (
    <div className="max-w-4xl mx-auto">
      <h3 className="text-2xl font-bold mb-4">Home Inspection Checklist</h3>
      <p className="text-sm italic text-gray-600 mb-4">
        Use this checklist during your home inspection to ensure you cover all important areas.
      </p>
      {/* Render each section of the checklist */}
      {renderChecklistSection("Exterior", checklist.exterior)}
      {renderChecklistSection("Interior", checklist.interior)}
      {renderChecklistSection("Systems", checklist.systems)}
      {renderChecklistSection("Other", checklist.other)}
    </div>
  );
}

export default InspectionChecklist;
import React from 'react';
import { formatNumber, LabelValue } from '../../utils/CustomComponents';

const MonthlyCostsPopup = ({ onClose, monthlyCosts, includeInternetTv, onToggleInternetTv, interestRates }) => {
  if (!monthlyCosts) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50" onClick={onClose}>
      <div className="bg-white p-6 rounded-lg shadow-lg max-w-md w-full relative" onClick={(e) => e.stopPropagation()}>
        <h2 className="text-xl text-center font-bold mb-6">Total Monthly Costs</h2>
        <div className="mb-6">
          {/* Mortgage */}
          <LabelValue label="Mortgage" value={`$${formatNumber(monthlyCosts.mortgage)}`} />
          {/* Property Tax */}
          <LabelValue label="Property Tax" value={`$${formatNumber(monthlyCosts.propertyTax)}`} />
          {/* Home Insurance */}
          <LabelValue label="Home Insurance" value={`$${formatNumber(monthlyCosts.homeInsurance)}`} />
          {/* Mortgage Insurance */}
          <LabelValue label="Mortgage Insurance" value={`$${formatNumber(monthlyCosts.mortgageInsurance)}`} />
          {/* HOA Fees */}
          <LabelValue label="HOA Fees" value={`$${formatNumber(monthlyCosts.hoaFees)}`} />
          {/* Utilities Estimate */}
          <LabelValue label="Utilities Estimate" value={`$${formatNumber(monthlyCosts.utilities)}`} />

          {/* Internet/TV with Toggle */}
          <div className="flex items-center justify-between">
            <LabelValue label="Internet/TV Estimate" />
            <div className="flex items-center">
              {/* Display "None" or the internetTV amount based on toggle */}
              <span className="mr-4">
                {includeInternetTv ? `$${formatNumber(monthlyCosts.internetTV)}` : 'None'}
              </span>

              {/* Toggle Switch */}
              <label htmlFor="includeInternetTvToggle" className="relative inline-flex items-center cursor-pointer">
                <input
                  type="checkbox"
                  id="includeInternetTvToggle"
                  className="sr-only peer"
                  checked={includeInternetTv}
                  onChange={onToggleInternetTv}
                />
                {/* Toggle Background */}
                <div className={`w-11 h-6 rounded-full transition-colors duration-200 ${
                  includeInternetTv ? 'bg-emerald-600' : 'bg-gray-200'
                }`}></div>
                {/* Toggle Knob */}
                <span
                  className={`absolute left-1 top-1 w-4 h-4 bg-white rounded-full transition-transform duration-200 
                  ${includeInternetTv ? 'translate-x-5' : 'translate-x-0'}`}
                ></span>
              </label>
            </div>
          </div>
        </div>

        {/* Total Monthly Cost */}
        <div className="border-t pt-4 mb-6">
          <LabelValue
            label="Total Monthly Cost"
            value={`$${formatNumber(monthlyCosts.total)}`}
          />
        </div>

        {/* Close Button */}
        <button
          onClick={onClose}
          className="w-full bg-emerald-600 text-white px-4 py-2 rounded hover:bg-emerald-700 transition duration-300"
        >
          Close
        </button>
      </div>
    </div>
  );
};

export default MonthlyCostsPopup;
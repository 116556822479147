import React from 'react';
import { useLocation, Link, useNavigate } from 'react-router-dom';
import ChatBot from './ChatBot';
import Title from '../Title';

const ChatBotPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const propertyData = location.state?.propertyData || null;
  const previousPath = location.state?.from || '/userapp'; // Default to /userapp if not specified

  const handleClose = () => {
    navigate(previousPath, { state: { analyzedProperty: propertyData } });
  };

  return (
    <div className="max-w-4xl mx-auto px-4 py-8">
      <Title />
      <div className="flex justify-between items-center">
        <Link to={previousPath} className="text-emerald-600 hover:text-emerald-500 font-semibold">
          ← Back to Analyzer
        </Link>
        <Link to={previousPath}
          onClick={handleClose} 
          className="text-emerald-600 hover:text-white font-bold text-l bg-transparent hover:bg-emerald-500 px-2 py-1 transition-colors duration-300"
          aria-label="Close"
        >
          ✖
        </Link>
      </div>
      <div className="bg-white rounded-lg overflow-hidden">
        {propertyData ? (
          <ChatBot propertyData={propertyData} />
        ) : (
          <p className="p-6 text-center text-gray-700">
            No property data available. Please analyze a property first.
          </p>
        )}
      </div>
    </div>
  );
};

export default ChatBotPage;
import { getFirestore, doc, setDoc, getDoc } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { formatHomeType } from '../../utils/CustomComponents';

const db = getFirestore();

export const savePreferences = async (preferences) => {
  const auth = getAuth();
  const user = auth.currentUser;

  if (!user) {
    throw new Error('No authenticated user found');
  }

  try {
    // Create a reference to a single preferences document for the user
    const preferencesDocRef = doc(db, 'users', user.uid, 'preferences', 'userPreferences');

    // Save or update the preferences
    await setDoc(preferencesDocRef, {
      idealPrice: preferences.idealPrice,
      bedrooms: preferences.bedrooms,
      bathrooms: preferences.bathrooms,
      propertyType: preferences.propertyType,
      squareFootage: preferences.squareFootage,
      updatedAt: new Date() // Adding a timestamp for when the preferences were last updated
    }, { merge: true }); // Use merge: true to update existing fields and add new ones without overwriting the entire document

    return 'userPreferences'; // Return the ID of the preferences document
  } catch (error) {
    console.error('Error saving preferences:', error);
    throw error;
  }
};

export const getPreferences = async (userId) => {
  try {
    const preferencesDocRef = doc(db, 'users', userId, 'preferences', 'userPreferences');
    const preferencesDoc = await getDoc(preferencesDocRef);

    if (preferencesDoc.exists()) {
      return preferencesDoc.data();
    } else {
      return null;
    }
  } catch (error) {
    console.error('Error fetching preferences:', error);
    throw error;
  }
};

export const calculateCompatibilityScore = (preferences, propertyData) => {
  let score = 0;

  // Ideal Price (45%)
  if (preferences.idealPrice === 'Any') {
    score += 45;
  } else {
    const priceDiff = Math.abs(propertyData.price - preferences.idealPrice) / preferences.idealPrice;
    if (priceDiff <= 0.1) {
      score += 45;
    } else if (priceDiff > 0.1 && priceDiff <= 0.2) {
      score += 30;
    } else if (priceDiff > 0.2 && priceDiff <= 0.3) {
      score += 15;
    }
  }

  // Bedrooms (15%)
  const propertyBedrooms = parseFloat(propertyData.bedrooms);
  if (preferences.bedrooms.length === 0 || preferences.bedrooms.includes(propertyBedrooms.toString())) {
    score += 15;
  } else if (preferences.bedrooms.some(bed => Math.abs(parseFloat(bed) - propertyBedrooms) <= 1)) {
    score += 7.5; // 50% of 15
  }

  // Bathrooms (10%)
  const propertyBathrooms = parseFloat(propertyData.bathrooms);
  if (preferences.bathrooms.length === 0 || preferences.bathrooms.includes(propertyBathrooms.toString())) {
    score += 10;
  } else if (preferences.bathrooms.some(bath => Math.abs(parseFloat(bath) - propertyBathrooms) <= 1)) {
    score += 5; // 50% of 10
  }

  // Property Type (15%)
  if (preferences.propertyType.includes(formatHomeType(propertyData.homeType))) {
    score += 15;
  }

  // Square Footage (15%)
  const squareFootageRanges = {
    '0 to 1000': [0, 1000],
    '1000 to 1500': [1000, 1500],
    '1500 to 2000': [1500, 2000],
    '2000 to 2500': [2000, 2500],
    '2500 to 3000': [2500, 3000],
    '3000 to 4000': [3000, 4000],
    '4000 to 5000': [4000, 5000],
    '5000+': [5000, Infinity]
  };

  if (preferences.squareFootage === '' || preferences.squareFootage === 'Any') {
    score += 15;
  } else {
    const selectedRange = squareFootageRanges[preferences.squareFootage];
    const propertySquareFootage = propertyData.squareFootage;

    if (propertySquareFootage >= selectedRange[0] && propertySquareFootage <= selectedRange[1]) {
      score += 15;
    } else {
      const ranges = Object.values(squareFootageRanges);
      const selectedIndex = ranges.findIndex(range => range === selectedRange);
      if (selectedIndex > 0 && propertySquareFootage >= ranges[selectedIndex - 1][0] && propertySquareFootage <= ranges[selectedIndex - 1][1]) {
        score += 10;
      } else if (selectedIndex < ranges.length - 1 && propertySquareFootage >= ranges[selectedIndex + 1][0] && propertySquareFootage <= ranges[selectedIndex + 1][1]) {
        score += 10;
      } else if (selectedIndex > 1 && propertySquareFootage >= ranges[selectedIndex - 2][0] && propertySquareFootage <= ranges[selectedIndex - 2][1]) {
        score += 5;
      } else if (selectedIndex < ranges.length - 2 && propertySquareFootage >= ranges[selectedIndex + 2][0] && propertySquareFootage <= ranges[selectedIndex + 2][1]) {
        score += 5;
      }
    }
  }

  return Math.round(score);
};

// Function to determine the grade and color based on the score
export const getGradeAndColor = (score) => {
  if (score === 100) return { grade: 'S', color: 'bg-gradient-to-r from-purple-500 via-indigo-500 to-blue-500 text-white' };
  if (score >= 90) return { grade: 'A+', color: 'bg-emerald-500 text-white' };
  if (score >= 80) return { grade: 'A', color: 'bg-green-500 text-white' };
  if (score >= 70) return { grade: 'B', color: 'bg-lime-500 text-white' };
  if (score >= 60) return { grade: 'C', color: 'bg-yellow-500 text-white' };
  if (score >= 50) return { grade: 'D', color: 'bg-orange-500 text-white' };
  return { grade: 'F', color: 'bg-red-500 text-white' };
};

// Function to get compatibility details
export const getCompatibilityDetails = (preferences, property) => {
  const details = [];
  const score = calculateCompatibilityScore(preferences, property);

  // Check each preference category
  if (Math.abs(preferences.idealPrice - property.price) / preferences.idealPrice <= 0.1) {
    details.push(`High: Price`);
  } else if (Math.abs(preferences.idealPrice - property.price) / preferences.idealPrice <= 0.2) {
    details.push(`Medium: Price`);
  } else if (Math.abs(preferences.idealPrice - property.price) / preferences.idealPrice <= 0.3) {
    details.push(`Low: Price`);
  } else {
    details.push(`None: Price`);
  }

  // Bedrooms (15%)
  const propertyBedrooms = parseFloat(property.bedrooms);
  if (preferences.bedrooms.length === 0 || preferences.bedrooms.includes(propertyBedrooms.toString())) {
    details.push(`High: Bedrooms`);
  } else if (preferences.bedrooms.some(bed => Math.abs(parseFloat(bed) - propertyBedrooms) <= 1)) {
    details.push(`Medium: Bedrooms`);
  } else {
    details.push(`None: Bedrooms`);
  } 

  // Bathrooms (10%)
  const propertyBathrooms = parseFloat(property.bathrooms);
  if (preferences.bathrooms.length === 0 || preferences.bathrooms.includes(propertyBathrooms.toString())) {
    details.push(`High: Bathrooms`);
  } else if (preferences.bathrooms.some(bath => Math.abs(parseFloat(bath) - propertyBathrooms) <= 1)) {
    details.push(`Medium: Bathrooms`);
  } else {
    details.push(`None: Bathrooms`);
  }

  if (preferences.propertyType.includes(formatHomeType(property.homeType))) {
    details.push(`High: Home Type`);
  } else {
    details.push(`None: Home Type`);
  }

  // Square Footage compatibility
  const squareFootageRanges = {
    '0 to 1000': [0, 1000],
    '1000 to 1500': [1000, 1500],
    '1500 to 2000': [1500, 2000],
    '2000 to 2500': [2000, 2500],
    '2500 to 3000': [2500, 3000],
    '3000 to 4000': [3000, 4000],
    '4000 to 5000': [4000, 5000],
    '5000+': [5000, Infinity]
  };

  if (preferences.squareFootage === '' || preferences.squareFootage === 'Any') {
    details.push(`High: Square Footage`);
  } else {
    const selectedRange = squareFootageRanges[preferences.squareFootage];
    const propertySquareFootage = property.squareFootage;

    if (propertySquareFootage >= selectedRange[0] && propertySquareFootage <= selectedRange[1]) {
      details.push(`High: Square Footage`);
    } else {
      const ranges = Object.values(squareFootageRanges);
      const selectedIndex = ranges.findIndex(range => range === selectedRange);
      if (selectedIndex > 0 && propertySquareFootage >= ranges[selectedIndex - 1][0] && propertySquareFootage <= ranges[selectedIndex - 1][1]) {
        details.push(`Medium: Square Footage`);
      } else if (selectedIndex < ranges.length - 1 && propertySquareFootage >= ranges[selectedIndex + 1][0] && propertySquareFootage <= ranges[selectedIndex + 1][1]) {
        details.push(`Medium: Square Footage`);
      } else if (selectedIndex > 1 && propertySquareFootage >= ranges[selectedIndex - 2][0] && propertySquareFootage <= ranges[selectedIndex - 2][1]) {
        details.push(`Low: Square Footage`);
      } else if (selectedIndex < ranges.length - 2 && propertySquareFootage >= ranges[selectedIndex + 2][0] && propertySquareFootage <= ranges[selectedIndex + 2][1]) {
        details.push(`Low: Square Footage`);
      } else {
        details.push(`No: Square Footage`);
      }
    }
  }

  return details.join('\n');
};

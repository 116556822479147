import React, { useState, useEffect } from 'react';
import { collection, getDocs, query, orderBy } from 'firebase/firestore';
import { db } from '../../firebase';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { formatNumber, formatHomeType } from '../../utils/CustomComponents';



const PropertyComparisonService = ({ properties, onAddGreenFlags, onAddRedFlags }) => {
  const [searchHistory, setSearchHistory] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [user, setUser] = useState(null);
  const [selectedProperty, setSelectedProperty] = useState(null);
  const [isVisible, setIsVisible] = useState(false);


  const attributes = [
    {
      label: 'Green Flags',
      key: 'greenFlags',
      formatter: (value, property) => (
        <div className="flex flex-col items-center -mt-2">
          <button
            className="bg-green-600 text-white px-3 py-1 rounded-full hover:bg-green-500 transition-colors"
            onClick={() => onAddGreenFlags(property)}
          >
            + Add
          </button>
          <div className="flex flex-wrap gap-1">
            {Array.isArray(value) && value.length > 0 ? (
              value.map((flag, index) => (
                <span
                  key={index}
                  className="bg-green-200 text-green-800 px-2 py-1 font-medium rounded-full text-sm"
                >
                  {flag}
                </span>
              ))
            ) : (
              <span className="text-gray-500 font-medium text-sm">None</span>
            )}
          </div>
        </div>
      )
    },
    {
      label: 'Red Flags',
      key: 'redFlags',
      formatter: (value, property) => (
        <div className="flex flex-col items-center -mt-2">
          <button
            className="bg-red-600 text-white px-3 py-1 rounded-full hover:bg-red-500 transition-colors"
            onClick={() => onAddRedFlags(property)}
          >
            + Add
          </button>
          <div className="flex flex-wrap gap-1">
            {Array.isArray(value) && value.length > 0 ? (
              value.map((flag, index) => (
                <span
                  key={index}
                  className="bg-red-200 text-red-800 px-2 py-1 font-medium rounded-full text-sm"
                >
                  {flag}
                </span>
              ))
            ) : (
              <span className="text-gray-500 font-medium text-sm">None</span>
            )}
          </div>
        </div>
      )
    },
    { label: 'Price', key: 'price', formatter: (value) => value ? `$${formatNumber(value)}` : 'N/A' },
    { label: 'Square Footage', key: 'squareFootage', formatter: (value) => value ? `${formatNumber(value)} sqft` : 'N/A' },
    { label: 'Price/sqft', key: 'pricePerSqFt', formatter: (value) => value !== 'N/A' ? `$${formatNumber(value)}` : 'N/A' },
    { label: 'Bedrooms', key: 'bedrooms', formatter: (value) => value || 'N/A' },
    { label: 'Bathrooms', key: 'bathrooms', formatter: (value) => value || 'N/A' },
  ];

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
      if (currentUser) {
        fetchSearchHistory(currentUser.uid);

      } else {
        setLoading(false);
        setError('User not authenticated. Please sign in to view search history.');
      }
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (!loading) {
      setIsVisible(true);
    }
  }, [loading]);

  const fetchSearchHistory = async (userId) => {
    try {
      setLoading(true);
      const searchHistoryRef = collection(db, 'users', userId, 'searchHistory');
      const q = query(searchHistoryRef, orderBy('timestamp', 'desc'));
      const querySnapshot = await getDocs(q);
      const history = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      console.log('Fetched search history:', history); // Debugging line
      setSearchHistory(history);
    } catch (error) {
      console.error('Error fetching search history:', error);
      setError('Failed to load search history. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  if (error) {
    return <div className="text-center py-8 text-red-600">{error}</div>;
  }

  return (
    <div className="overflow-x-auto">
  <table className="min-w-full bg-white border border-gray-200">
    <thead>
      <tr>
        <th className="py-3 px-4 border-b border-gray-200 bg-gray-100 text-left text-lg font-semibold text-gray-700 sticky left-0 z-10">
          Property
        </th>
        {searchHistory.map((property, index) => (
          <th
            key={index}
            className="py-3 px-4 border-b border-gray-200 bg-gray-100 text-center text-sm font-semibold text-gray-700"
          >
            <div className="flex flex-col items-center">

              <img
                src={property.image1}
                alt={property.address}
                className="w-4/5 object-cover mb-2 rounded"
              />

              {property.zillowUrl ? (
                <a
                  href={property.zillowUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600"
                >
                  {property.address}
                </a>
              ) : (
                property.address
              )}
            </div>
          </th>
        ))}
      </tr>
    </thead>
    <tbody>

    {attributes.map((attribute) => (
        <tr key={attribute.label}>
          <td className="py-3 px-4 border-b border-gray-200 bg-gray-50 font-medium text-gray-700 sticky left-0 z-10">
            {attribute.label}
          </td>
          {searchHistory.map((property, index) => (
            <td
              key={index}
              className="py-3 px-4 border-b border-gray-200 text-center text-gray-700"
            >
              {attribute.formatter(property[attribute.key], property)}
            </td>
          ))}
        </tr>
      ))}

    </tbody>
  </table>
</div>
  )
  



};

export default PropertyComparisonService;




import mixpanel from 'mixpanel-browser';

// Define the step mapping
const stepBackMap = {
  1.5: 1,
  2: 1,
  2.5: 2,
  3: 2,
  4: 3,
};

// Track events with Mixpanel
const trackEvent = (event, properties = {}) => {
  mixpanel.track(event, properties);
};

// Handle financing choice
const handleFinancingChoice = (choice, setStep, setHasFinancing) => {
  trackEvent('Financing Choice', { choice: choice ? 'Yes' : 'No' });
  // console.log('Financing Choice:', choice);
  setHasFinancing(choice);
  if (choice) {
    setStep(2);
  } else {
    setStep(1.5);
  }
};

// Handle found home choice
const handleFoundHomeChoice = (choice, setStep, setFoundHome) => {
  trackEvent('Found Home Choice', { choice: choice ? 'Yes' : 'No' });
  // console.log('Found Home Choice:', choice);
  setFoundHome(choice);
  if (choice) {
    setStep(2.5); // Transition to the new step instead of navigating immediately
  } else {
    setStep(3);
  }
};

// Handle priority choice
const handlePriorityChoice = (choice, setStep, setPriority) => {
  trackEvent('Priority Choice', { priority: choice });
  // console.log('Priority Choice:', choice);
  setPriority(choice);
  setStep(4);
};

// Handle back navigation
const handleBack = (currentStep, setStep, resetFunctions = {}) => {
  const prevStep = stepBackMap[currentStep];
  if (prevStep) {
    setStep(prevStep);
    // Reset state variables if needed
    if (currentStep === 2.5 && resetFunctions.resetFoundHome) {
      resetFunctions.resetFoundHome();
    }
    if (currentStep === 3 && resetFunctions.resetPriority) {
      resetFunctions.resetPriority();
    }
    // Add more conditions if other steps require resetting
  }
};

// Handle explore action
const handleExploreSignUp = (currentStep, navigate) => {
  trackEvent('Sign Up Button Clicked', { step: currentStep });
  // console.log('Sign Up Button Clicked:', currentStep);
  navigate('/walkthrough/signup');
};

const handleExploreApp = (currentStep, navigate) => {
  trackEvent('Explore Button Clicked', { step: currentStep });
  console.log('Explore Button Clicked:', currentStep);
  navigate('/app');
};

// Export all functions
export {
  handleFinancingChoice,
  handleFoundHomeChoice,
  handlePriorityChoice,
  handleBack,
  handleExploreSignUp,
  handleExploreApp
};
import React, { useState, useEffect } from 'react';
import confetti from "canvas-confetti";
import { getProgressBarColor, renderChecklistItem } from '../utils/CustomComponents';

const Closing = () => {
  const [uploadMessage, setUploadMessage] = useState("");
  // State to manage the checklist items
  const [checklist, setChecklist] = useState({
    earnestMoney: false,
    mortgageFunding: false,
    escrow: false,
    inspections: false,
    appraisal: false,
    titleSearch: false,
    homeownersInsurance: false
  });

  // State to manage the progress percentage
  const [progress, setProgress] = useState(0);

  // Function to trigger confetti
  const triggerConfetti = () => {
    const scalar = 2;
    const home = confetti.shapeFromText({ text: "🏠", scalar });
    const star = confetti.shapeFromText({ text: "✨", scalar });
    const check = confetti.shapeFromText({ text: "✅", scalar });
  
    const defaults = {
      particleCount: 100,
      spread: 360,
      ticks: 60,
      gravity: 0,
      decay: 0.96,
      startVelocity: 20,
      shapes: [home, star, check],
      scalar,
    };
  
    const shoot = () => {
      confetti({
        ...defaults,
        particleCount: 30,
      });
  
      confetti({
        ...defaults,
        particleCount: 5,
      });
  
      confetti({
        ...defaults,
        particleCount: 15,
        scalar: scalar / 2,
        shapes: ["circle"],
      });
    };
  
    setTimeout(shoot, 0);
    setTimeout(shoot, 100);
    setTimeout(shoot, 200);
  };

  // Effect to update progress and trigger confetti
  useEffect(() => {
    const completedItems = Object.values(checklist).filter(Boolean).length;
    const totalItems = Object.keys(checklist).length;
    const newProgress = (completedItems / totalItems) * 100;
    setProgress(newProgress);

    if (newProgress === 100) {
      triggerConfetti();
    }
  }, [checklist]);

  const handleCheckboxChange = (item) => {
    setChecklist(prevState => ({
      ...prevState,
      [item]: !prevState[item]
    }));
  };

  return (
    <div className="max-w-4xl mx-auto">
      <h3 className="text-2xl font-bold mb-4">Closing Checklist</h3>

      {/* Progress bar, dynamically changes based on checklist items, red-orange-yellow-green gradient */}
      <div className="w-full h-4 bg-gray-200 rounded-full mb-6">
        <div 
          className={`h-full rounded-full transition-all duration-500 ${getProgressBarColor(progress)}`}
          style={{ width: `${progress}%` }}
        ></div>
      </div>
      
      {/* Checklist items */}
      <ul className="space-y-2">
        {Object.entries(checklist).map(([key, value]) => (
          renderChecklistItem(
            key,
            value,
            () => handleCheckboxChange(key),
            (() => {
              switch(key) {
                case 'earnestMoney':
                  return 'Pay earnest money deposit';
                case 'mortgageFunding':
                  return 'Confirm mortgage/loans/funding';
                case 'escrow':
                  return 'Confirm escrow';
                case 'inspections':
                  return 'Confirm inspections scheduled';
                case 'appraisal':
                  return 'Confirm appraisal schedule and completed';
                case 'titleSearch':
                  return 'Confirm title search';
                case 'homeownersInsurance':
                  return 'Confirm homeowner\'s insurance';
                default:
                  return key;
              }
            })()
          )
        ))}
      </ul>

      {/* Document Analyzer section */}
      <h3 className="text-2xl font-bold mt-8 mb-4">Document Analyzer</h3>
      <p className="text-sm italic text-gray-600 mb-4">Upload your documents for AI-powered analysis</p>
      <div className="mt-6">
        {/* Custom styled button for file upload */}
        <label htmlFor="documentUpload" className="cursor-pointer bg-emerald-600 text-white px-4 py-2 rounded hover:bg-emerald-500 transition duration-300">
          Choose File
        </label>
        {/* Hidden file input, triggered by the label above */}
        <input type="file" id="documentUpload" accept=".pdf, .docx, .txt" className="hidden"
          onChange={(e) => {
            // Check if a file was selected, i.e. if user pressed Cancel (didn't select a file) -> no message
            if (e.target.files.length > 0) {
              setUploadMessage("Document uploaded. This feature is a work in progress and will be ready soon!");
            }
          }}
        />
      </div>
      {/* Display message if a file was uploaded */}
      {uploadMessage && <p className="mt-2 text-sm text-emerald-600">{uploadMessage}</p>}
    </div>
  );
};

export default Closing;
import React, { useState, useEffect } from 'react';
import { FaChartPie, FaSearchDollar, FaClipboardCheck, FaChevronDown } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import Footer from './Footer';
import { sendSignUpEmail } from '../../utils/CustomComponents';
import mixpanel from 'mixpanel-browser';

const LandingPage = () => {
  const [email, setEmail] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [activeSection, setActiveSection] = useState('');

  useEffect(() => {
    const handleScroll = () => {
      const sections = ['hero', 'early-access', 'unrepped-path', 'benefits', 'faq'];
      const current = sections.find(section => {
        const element = document.getElementById(section);
        if (element) {
          const rect = element.getBoundingClientRect();
          return rect.top <= 150 && rect.bottom >= 150;
        }
        return false;
      });
      setActiveSection(current || '');
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const handleStart = (location) => {
    // Tracking function for insights on where people like to start (e.g. is CTA section useful?)
    console.log('Start button clicked from:', location);
    mixpanel.track(`Start (${location})`);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log('Email submitted:', email);
    setSubmitted(true);
    await sendSignUpEmail(email);
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-emerald-50 via-teal-50 to-cyan-50 text-gray-800 font-inter">
      <header className="fixed w-full px-2 sm:px-4 py-4 sm:py-4 flex justify-between items-center bg-white shadow-md z-50">
        <a href="#" className="-m-1.5 p-1.5">
          <span className="sr-only">Unrepped</span>
          <img
            alt="Unrepped logo"
            src="/unrepped-logotype.png"
            className="h-9 w-auto"
          />
        </a>
        {}
        <nav className="hidden md:flex space-x-6">
          {[
            { name: 'Early Access', id: 'early-access' },
            { name: 'The UNREPPED Path', id: 'unrepped-path' },
          ].map(({ name, id }) => (
            <a
              key={id}
              href={`#${id}`} 
              className={`text-sm font-semibold ${
                activeSection === id
                  ? 'text-emerald-600'
                  : 'text-gray-600 hover:text-emerald-600'
              }`}
            >
              {name}
            </a>
          ))}
        </nav>
        <Link 
  to="/signin" 
  onClick={() => handleStart('header')} // Added onClick for header
  className="rounded-full bg-emerald-600 px-5 py-3 text-base font-semibold text-white shadow-md hover:bg-emerald-500 transition-all duration-300 ease-in-out hover:scale-105"
>
  Log In
</Link>

      </header>

      <main className="pt-20">
        {/* Hero Section */}
        <section id="hero" className="relative w-full px-4 py-20 text-center overflow-hidden bg-gradient-to-r from-emerald-100 to-teal-100">
          {/* Adjusted text sizes using responsive classes */}
          <h1 className="text-4xl sm:text-5xl md:text-6xl lg:text-7xl xl:text-8xl font-bold mb-6 leading-tight">
            Find Your Dream Home <br />
            <span className="text-emerald-700">Without the Hassle</span>
          </h1>
          {/* Adjusted paragraph text size */}
          <p className="text-base sm:text-lg md:text-xl mb-8 max-w-xl mx-auto">
            Unrepped puts YOU in control of your home-buying journey. 
            Complete financial insights, personalized guidance, and a seamless experience - all in one place.
          </p>

          <div className="mt-10 flex items-center justify-center">
            <Link 
              to="/walkthrough" 
              onClick={() => handleStart('hero')} // Added onClick for hero section
              className="rounded-full bg-emerald-600 px-8 py-4 text-lg font-semibold text-white shadow-lg hover:bg-emerald-500 transition-all duration-300 ease-in-out transform hover:scale-105"
            >
              Start Your Home Search
            </Link>
          </div>
          <div className="absolute bottom-0 left-0 right-0 flex justify-center">
            <FaChevronDown className="animate-bounce text-3xl text-emerald-600" />
          </div>
        </section>

        {}
        <section id="early-access" className="w-full px-4 py-20 bg-gradient-to-r from-teal-100 to-cyan-100">
          <div className="max-w-6xl mx-auto">
            {/* Adjusted subheading text size */}
            <h2 className="text-2xl sm:text-3xl font-bold mb-6 text-center">Get Early Access!</h2>
            {/* Adjusted paragraph text size */}
            <p className="text-base sm:text-lg md:text-xl mb-8 max-w-2xl mx-auto text-center">
              Be among the first to experience Unrepped! Sign up for exclusive updates and early access to our revolutionary home-buying platform. 
              <br />
              No BS. No spam. Full transparency.
            </p>
            <div className="max-w-md mx-auto">
              {!submitted ? (
                <form onSubmit={handleSubmit} className="flex flex-nowrap gap-2">
                  <input
                    type="email"
                    placeholder="Enter your email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="m-0 flex-grow min-w-0 px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-emerald-500 bg-white"
                    required
                  />
                  <button
                    type="submit"
                    className="m-0 flex-shrink-0 rounded-md bg-emerald-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-emerald-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-emerald-600"
                  >
                    Sign Up
                  </button>
                </form>
              ) : (
                <div className="text-lg font-semibold text-emerald-700 text-center">
                  Thanks for joining! We'll keep you updated on our launch progress.
                </div>
              )}
            </div>
          </div>
        </section>

        {/* The UNREPPED Path Section */}
        <section id="unrepped-path" className="w-full px-4 py-20 bg-gradient-to-r from-cyan-100 to-emerald-100">
          <h2 className="text-2xl sm:text-3xl font-bold mb-6 text-center">The UNREPPED Path</h2>
          <p className="text-center text-lg mb-12 max-w-md mx-auto">
            Our AI-driven tools guides you through the home buying process without any commissions or fees.
          </p>
          {/*we need to work on this copy*/}
          <div className="grid md:grid-cols-3 gap-8 max-w-4xl mx-auto">
            <div className="text-center bg-white bg-opacity-50 rounded-lg p-6 shadow-lg">
              <div className="bg-emerald-200 rounded-full p-6 inline-block mb-4">
                <FaChartPie className="h-12 w-12 text-emerald-600" />
              </div>
              <h3 className="text-lg sm:text-xl font-semibold mb-2">Cost Breakdown</h3>
              <p className="text-sm sm:text-base">
                Understand your costs upfront, from mortgage to monthly payments.
              </p>
            </div>
            <div className="text-center bg-white bg-opacity-50 rounded-lg p-6 shadow-lg">
              <div className="bg-emerald-200 rounded-full p-6 inline-block mb-4">
                <FaSearchDollar className="h-12 w-12 text-emerald-600" />
              </div>
              <h3 className="text-lg sm:text-xl font-semibold mb-2">Market Comparison</h3>
              <p className="text-sm sm:text-base">
                See what homes are selling for and know what to offer.
              </p>
            </div>
            <div className="text-center bg-white bg-opacity-50 rounded-lg p-6 shadow-lg">
              <div className="bg-emerald-200 rounded-full p-6 inline-block mb-4">
                <FaClipboardCheck className="h-12 w-12 text-emerald-600" />
              </div>
              <h3 className="text-lg sm:text-xl font-semibold mb-2">Document Analysis</h3>
              <p className="text-sm sm:text-base">
                Analyze your documents and stay organized until the deal is done.
              </p>
            </div>
          </div>
        </section>

        {/* Benefits Section */}
        {/* 
        <section id="benefits" className="w-full px-4 py-20 bg-gradient-to-r from-emerald-100 to-teal-100">
          <div className="max-w-6xl mx-auto">
            <h2 className="text-2xl sm:text-3xl font-bold mb-12 text-center">Why Choose Unrepped?</h2>
            <div className="grid md:grid-cols-2 gap-8">
              <div className="bg-white bg-opacity-50 p-6 rounded-lg shadow-lg">
                <h3 className="text-lg sm:text-xl font-semibold mb-2 text-emerald-700">Save Thousands</h3>
                <p className="text-sm sm:text-base">
                  Keep more of your money by avoiding traditional agent commissions.
                </p>
              </div>
              <div className="bg-white bg-opacity-50 p-6 rounded-lg shadow-lg">
                <h3 className="text-lg sm:text-xl font-semibold mb-2 text-emerald-700">Powerful Tools</h3>
                <p className="text-sm sm:text-base">
                  Access professional-grade property analysis and valuation tools.
                </p>
              </div>
              <div className="bg-white bg-opacity-50 p-6 rounded-lg shadow-lg">
                <h3 className="text-lg sm:text-xl font-semibold mb-2 text-emerald-700">Expert Guidance</h3>
                <p className="text-sm sm:text-base">
                  Get step-by-step support throughout the entire home buying process.
                </p>
              </div>
              <div className="bg-white bg-opacity-50 p-6 rounded-lg shadow-lg">
                <h3 className="text-lg sm:text-xl font-semibold mb-2 text-emerald-700">Transparent Process</h3>
                <p className="text-sm sm:text-base">
                  Enjoy a clear, straightforward approach to home buying without hidden agendas.
                </p>
              </div>
            </div>
          </div>
        </section>
        */}

        {/* FAQ Section */}
        {/* 
        <section id="faq" className="w-full px-4 py-20 bg-gradient-to-r from-teal-100 to-cyan-100">
          <h2 className="text-2xl sm:text-3xl font-bold mb-12 text-center">Frequently Asked Questions</h2>
          <div className="space-y-6 max-w-2xl mx-auto">
            <div className="bg-white bg-opacity-50 p-6 rounded-lg shadow-lg">
              <h3 className="text-lg sm:text-xl font-semibold mb-2 text-emerald-700">How does Unrepped make home buying easier?</h3>
              <p className="text-sm sm:text-base">
                We provide powerful tools, market insights, and step-by-step guidance to empower you throughout the entire process.
              </p>
            </div>
            <div className="bg-white bg-opacity-50 p-6 rounded-lg shadow-lg">
              <h3 className="text-lg sm:text-xl font-semibold mb-2 text-emerald-700">Can I really save money by not using an agent?</h3>
              <p className="text-sm sm:text-base">
                Yes! By skipping traditional agent fees, you can save an average of 2.5-3% of the home's purchase price.
              </p>
            </div>
            <div className="bg-white bg-opacity-50 p-6 rounded-lg shadow-lg">
              <h3 className="text-lg sm:text-xl font-semibold mb-2 text-emerald-700">What kind of support does Unrepped offer?</h3>
              <p className="text-sm sm:text-base">
                We provide tools for property valuation, offer creation, negotiation strategies, and personalized guidance at every step.
              </p>
            </div>
          </div>
        </section> 
        */}

        {/* Call to Action Section */}
        <section id="cta" className="w-full px-4 py-20 bg-gradient-to-r from-emerald-600 to-teal-600 text-white text-center">
          <h2 className="text-3xl sm:text-4xl font-bold mb-6">Ready to Find Your Dream Home?</h2>
          <p className="text-base sm:text-lg md:text-xl mb-8 max-w-2xl mx-auto">
            Join Unrepped today and experience a smarter way to buy your home.
          </p>
          <Link 
            to="/walkthrough" 
            onClick={() => handleStart('CTA')} // Added onClick for CTA section
            className="inline-block rounded-full bg-white text-emerald-600 px-8 py-4 text-lg font-semibold shadow-lg hover:bg-emerald-50 transition-all duration-300 ease-in-out transform hover:scale-105"
          >
            Get Started Now
          </Link>
        </section>
      </main>

      <Footer />
    </div>
  );
};

export default LandingPage;
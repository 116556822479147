import React, { useState } from 'react';
import { FaThumbsUp, FaThumbsDown } from 'react-icons/fa';
import { sendFeedbackEmail } from '../utils/CustomComponents';
import mixpanel from 'mixpanel-browser';

const FeedbackForm = () => {
  const [isPopupOpen, setPopupOpen] = useState(false);
  const [hasLiked, setHasLiked] = useState(null);
  const [showThankYou, setShowThankYou] = useState(false);
  const [feedback, setFeedback] = useState("");
  const [emails, setEmail] = useState("");
  const [consensus, setConsensus] = useState("");
  const [selectedButton, setSelectedButton] = useState(""); // Track which button is selected

  const togglePopup = () => {
    setPopupOpen(!isPopupOpen);
  };

  const closePopupOutside = (e) => {
    if (e.target === e.currentTarget) {
      setPopupOpen(false);
    }
  };

  const handleFeedbackChange = (event) => {
    setFeedback(event.target.value);
  };

  const handleLike = () => {
    setConsensus('Thumbs Up');
    setSelectedButton('like'); // Set the selected button to like
  };

  const handleDislike = () => {
    setConsensus('Thumbs Down');
    setSelectedButton('dislike'); // Set the selected button to dislike
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleSubmitFeedback = async (event) => {
    event.preventDefault();
    setShowThankYou(true);
    setTimeout(() => {
      setShowThankYou(false);
      setPopupOpen(false);
    }, 1500);

    // Identify the user by email
    mixpanel.identify(emails);

    // Set user properties in Mixpanel's People profile
    mixpanel.people.set({
      "$email": emails, // storing the user's email in the profile
      "last_feedback": feedback, // store the latest feedback given
      "feedback_type": consensus, // store whether it's thumbs up or down
    });

    // Track the event with feedback and email
    if (consensus === 'Thumbs Up') {
      mixpanel.track('Feedback Liked', {
        feedback: feedback, // track the feedback content
        email: emails // capture the user's email
      });
    } else if (consensus === 'Thumbs Down') {
      mixpanel.track('Feedback Disliked', {
        feedback: feedback, // track the feedback content
        email: emails // capture the user's email
      });
    }

    // Send feedback via email function
    await sendFeedbackEmail(feedback, emails, consensus);

    // Clear form fields after submission
    setConsensus('');
    setFeedback('');
    setEmail('');
  };


  return (
    <>
      <button onClick={togglePopup} className="fixed bottom-5 right-5 bg-emerald-600 text-white px-6 py-3 rounded-full hover:bg-emerald-500 transition duration-300 shadow-lg">
        Feedback
      </button>
      {isPopupOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50" onClick={closePopupOutside}>
          <div className="bg-white p-6 rounded-lg shadow-xl w-full max-w-sm sm:max-w-md md:max-w-lg mx-4" onClick={(e) => e.stopPropagation()}>
            {showThankYou ? (
              <h2 className="text-2xl font-bold mb-4 text-center">Thank you for submitting!</h2>
            ) : (
              <>
                <h2 className="text-2xl font-bold mb-4 text-center">What do you think?</h2>
                <div className="flex justify-center mb-4 space-x-4">
                  <button
                    className={`bg-emerald-100 text-emerald-600 hover:bg-emerald-200 hover:text-emerald-700 p-4 rounded-full 
    ${selectedButton === 'like' ? 'border-2 border-emerald-600 ring-emerald-600 ring-offset-2 ring-2 rounded-full' : ''}`}
                    onClick={handleLike}
                  >
                    <FaThumbsUp size={28} />
                  </button>
                  <button
                    className={`bg-red-100 text-red-600 hover:bg-red-200 hover:text-red-700 p-4 rounded-full 
    ${selectedButton === 'dislike' ? 'border-2 border-red-600 ring-red-600 ring-offset-2 ring-2 rounded-full' : ''}`}
                    onClick={handleDislike}
                  >
                    <FaThumbsDown size={28} />
                  </button>

                </div>
                <p className="mb-4 text-center">
                  Give feedback below!
                  <br className="sm:hidden" />
                  <p>or <a href="tel:845-535-9673" className="text-blue-600 hover:underline">Call Us</a> at 845-535-9673</p>
                </p>
                <form onSubmit={handleSubmitFeedback} className="space-y-4">
                  <textarea
                    className="w-full h-40 p-2 border-2 border-gray-300 rounded resize-none focus:border-emerald-500 focus:outline-none"
                    value={feedback}
                    onChange={handleFeedbackChange}
                    required
                  />
                  <p className="text-center">Email Address</p>
                  <textarea
                    className="w-full h-11 p-2 border-2 border-gray-300 rounded resize-none focus:border-emerald-500 focus:outline-none"
                    value={emails}
                    onChange={handleEmailChange}
                    required
                  />
                  <button type="submit" className="w-full bg-emerald-600 text-white py-2 rounded hover:bg-emerald-500 transition duration-300">Submit</button>
                </form>
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default FeedbackForm;

import React, { useState, useEffect } from 'react';
import { FaHome, FaExchangeAlt, FaCalendarAlt, FaCalculator, FaClipboardCheck, FaChartLine, FaFileSignature, FaCheckCircle } from 'react-icons/fa';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import LandingPage from './components/landingpage/LandingPage';
import WalkthroughView from './components/landingpage/walkthrough/WalkthroughView';
import SignIn from './components/landingpage/walkthrough/authenticate/SignIn';
import SignInWalkthrough from './components/landingpage/walkthrough/authenticate/SignInWalkthrough';
import SignUp from './components/landingpage/walkthrough/authenticate/SignUp';
import SignUpWalkthrough from './components/landingpage/walkthrough/authenticate/SignUpWalkthrough';
import Title from './components/Title';
import UserApp from './UserApp';
import PropertyAnalysisView from './components/propertyanalysis/PropertyAnalysisView';
import PropertyComparisonView from './components/propertycomparison/PropertyComparisonView';
import ViewingRequest from './components/ViewingRequest';
import MortgageCalculatorView from './components/mortgagecalculator/MortgageCalculatorView';
import InspectionChecklist from './components/InspectionChecklist';
import AccountManagement from './components/usernav/AccountManagement';
import CMAView from './components/cma/CMAView';
import MakeOffer from './components/MakeOffer';
import Closing from './components/Closing';
import RecentAnalyses from './components/RecentAnalyses';
import FeatureButton from './components/FeatureButton';
import ChatBotPage from './components/chatbot/ChatBotPage';
import FeedbackForm from './components/FeedbackForm';
import { MaxWidthLayout } from './utils/CustomComponents';
import SearchHistory from './components/usernav/SearchHistory';
import DownPayment from './components/propertyanalysis/DownPayment'
import Preferences from './components/usernav/Preferences'

const App = () => {
  useEffect(() => {
    var _mtm = window._mtm = window._mtm || [];
    _mtm.push({'mtm.startTime': (new Date().getTime()), 'event': 'mtm.Start'});
    var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
    g.async=true; g.src='https://cdn.matomo.cloud/unrepped.matomo.cloud/container_2AjDEjEn.js'; s.parentNode.insertBefore(g,s);
  }, [])

  const [currentView, setCurrentView] = useState("analyzeproperty");
  const [analyzedProperties, setAnalyzedProperties] = useState([]);
  const [showDemoPopup, setShowDemoPopup] = useState(true);
  const [recentAnalyses, setRecentAnalyses] = useState([]);
  const [comparableHomes, setComparableHomes] = useState([]);
  const [analyzedProperty, setAnalyzedProperty] = useState(null);
  const [propertyAnalyzed, setPropertyAnalyzed] = useState(false);

  const DemoPopup = ({ onClose }) => (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white p-6 rounded-lg shadow-xl max-w-sm w-full mx-4 text-center">
        <p className="mb-4">Hey, thanks for checking out our demo!</p>
        <p className="mb-4">We're under construction, but we'd love feedback at <a href="mailto:jerry@unrepped.co" className="text-emerald-600 hover:underline">jerry@unrepped.co</a></p>
        <button onClick={onClose} className="bg-emerald-600 text-white px-4 py-2 rounded hover:bg-emerald-700 transition duration-300">Got it!</button>
      </div>
    </div>
  );

  const addProperty = (property) => {
    console.log("Adding property:", property);
    const propertyExists = analyzedProperties.some(p => p.zpid === property.zpid);
    if (!propertyExists) {
      setAnalyzedProperties(prevProperties => [...prevProperties, property]);
    }
    setAnalyzedProperty(property);
    setRecentAnalyses(prevAnalyses => {
      const newAnalyses = [property, ...prevAnalyses.filter(p => p.zpid !== property.zpid)].slice(0, 5);
      console.log("Updated recentAnalyses:", newAnalyses);
      return newAnalyses;
    });
    setComparableHomes([]);
    setPropertyAnalyzed(true);
  };

  const features = [
    { name: 'Analyze Property', icon: <FaHome className="w-6 h-6" />, order: 1 },
    { name: 'Compare Properties', icon: <FaExchangeAlt className="w-6 h-6" />, order: 2 },
    { name: 'Market Analysis', icon: <FaChartLine className="w-6 h-6" />, order: 3 },
    { name: 'Mortgage Calculator', icon: <FaCalculator className="w-6 h-6" />, order: 4 },
    { name: 'Request Viewing', icon: <FaCalendarAlt className="w-6 h-6" />, order: 5 },
    { name: 'Inspection Checklist', icon: <FaClipboardCheck className="w-6 h-6" />, order: 6 },
    { name: 'Make Offer', icon: <FaFileSignature className="w-6 h-6" />, order: 7 },
    { name: 'Closing', icon: <FaCheckCircle className="w-6 h-6" />, order: 8 },
  ];

  return (
    <div className="min-h-screen bg-gradient-to-br from-emerald-50 via-teal-50 to-cyan-50 text-gray-800 font-inter">
      <Router>
        <Routes>
          {/* Public Routes without MaxWidthLayout */}
          <Route path="/" element={<LandingPage />} />
          <Route path="/walkthrough" element={<WalkthroughView />} />
          <Route path= "/walkthrough/signup" element={<SignUpWalkthrough />} />
          <Route path= "/walkthrough/signin" element={<SignInWalkthrough />} />
          <Route path="/signin" element={<SignIn />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/userapp/assistanceprograms" element={<DownPayment />} />
          

          {/* Protected/Styled Routes with MaxWidthLayout */}
          <Route path="/app" element={
            <MaxWidthLayout>
              <AppContent />
              <FeedbackForm />
            </MaxWidthLayout>
          } />

          <Route path="/userapp" element={
            <MaxWidthLayout>
              <UserApp />
              <FeedbackForm />
            </MaxWidthLayout>
          } />

          <Route path="/chatbot" element={
            <MaxWidthLayout>
              <ChatBotPage />
              {/* No FeedbackForm for chatbot */}
            </MaxWidthLayout>
          } />

          <Route path="/userapp/history" element={
            <MaxWidthLayout>
              <SearchHistory />
              <FeedbackForm />
            </MaxWidthLayout>
          } />

          <Route path="/userapp/preferences" element={
            <MaxWidthLayout>
              <Preferences />
              <FeedbackForm />
            </MaxWidthLayout>
          } />
        </Routes>
      </Router>
    </div>
  );

  function AppContent() {
    return (
      <div>
        {/* Uncomment the line below if you want to show the DemoPopup */}
        {/* {showDemoPopup && <DemoPopup onClose={() => setShowDemoPopup(false)} />} */}
        <Title />

        {propertyAnalyzed && (
          <div className="grid grid-cols-2 sm:grid-cols-4 lg:grid-cols-4 gap-4 mb-8">
            {features.map((feature, index) => (
              <FeatureButton 
                key={index} 
                name={feature.name} 
                icon={feature.icon} 
                onClick={() => setCurrentView(feature.name.toLowerCase().replace(/ /g, ""))}
                isActive={currentView === feature.name.toLowerCase().replace(/ /g, "")}
                number={feature.order} // Pass the order number
              />
            ))}
          </div>
        )}

        {currentView === "analyzeproperty" && (
          <PropertyAnalysisView addProperty={addProperty} analyzedProperty={analyzedProperty} />
        )}

        {propertyAnalyzed && (
          <>
            {currentView === "compareproperties" && (
              <PropertyComparisonView properties={analyzedProperties} />
            )}
            {currentView === "marketanalysis" && (
              <CMAView recentAnalyses={recentAnalyses} comparableHomes={analyzedProperty?.similarHomes || []} />
            )}
            {currentView === "mortgagecalculator" && <MortgageCalculatorView lastAnalyzedProperty={analyzedProperty} />}
            {currentView === "requestviewing" && <ViewingRequest lastAnalyzedProperty={analyzedProperty} />}
            {currentView === "inspectionchecklist" && <InspectionChecklist />}
            {currentView === "makeoffer" && <MakeOffer lastAnalyzedProperty={analyzedProperty} />}
            {currentView === "closing" && <Closing />}
            {recentAnalyses.length > 0 && <RecentAnalyses recentProperties={recentAnalyses} />}
          </>
        )}
      </div>
    );
  }
};

export default App;